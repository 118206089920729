<template>
  <div class="home mt-2 mb-5">
     <div class="container">
      <div class="py-2 text-center">
        Hello {{app.user.username}},<br>
        <span class="tiny">You are connected to {{app.api.server}}.</span>
      </div>
    </div>

    <div class="container">
      <div v-if="info" class="pt-3 pb-2">
        <p class="pb-1">
          If information is missing, please synchronize first. Contact support if the information is still not available.
        </p>

        <div class="accordion pt-1 pb-3" id="installations">
          <div v-for="(item,index) in installations" :key="'installation_' +item.id" class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button p-2 py-3" :class="{ collapsed: activeIndex !== index }" type="button" data-remark-toggle="collapse" :data-bs-target="'#installation_'+item.id" :aria-expanded="activeIndex === index" :aria-controls="'installation_'+item.id" @click="toggleAccordion(index)">
                {{item.code}}: {{item.name}}
              </button>
            </h2>
            <div :id="'installation_' +item.id" class="accordion-collapse pt-1 pb-3" :class="{ show: activeIndex === index }">
              <div class="accordion-body">
                <div class="row">
                  <div v-for="d in getModules(item.id)" :key="'module_' +d.id" class="px-1 col-xl-3 col-lg-3 col-md-4 col-6">
                    <div class="text-center module pt-1 mb-3">
                      <small class="">{{d.description || d.filename}}</small>
                      <img @click="doloadModule(d)" 
                        :id="'module_' +d.id" 
                        :alt="d.filename" 
                        :data-src="d.fk_installation +'/' +d.filename" 
                        class="img-fluid pt-1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg==" /><br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mt-3 text-center">
        The app needs to download information first.
        <br>
        <router-link class="btn btn-primary px-3 mt-4" to="/sync" replace><i class="fas fa-cloud-download-alt"></i>Synchronize</router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
#installations .accordion-collapse:not(.show) {
  display: none;
}
#installations .accordion-body .module {
  max-height: 60vw;
  overflow: hidden;
  border: 1px solid #eee;
}
#installations div small {
  display: block;
}

td {
  padding-left: 0;
}
/* Hide the button when a accordion item is expanded */
#accordionInfo .accordion-button:not(.collapsed)::after {
  display: none;
}
#modules img {
  border: 1px solid gray;
}
</style>

<script>
export default {
  name: 'Home',

  data() {
    return {
      // Index of the currently open accordion item
      activeIndex: 0,
      // When the database updates, it will transmit an event that gets picked up; it will use this forceRedraw to force a -wait for it- redraw
      forceRedraw: 0,
      lmra: [],
    }
  },

  computed: {
    info() {
      let installations = this.app.storage.get('info.installation');
      return installations;
    }
  },

  methods: {
    toggleAccordion(index) {
      // Close the currently open accordion if clicked again
      console.log('toggleAccordion('+index+')');
      this.activeIndex = this.activeIndex === index ? null : index;
    },

    doLoadForm(key) {
      console.log('doLoadForm(' +key +')');
      this.app.loadForm(key);
    },

    showTodo(choice) {
      console.log('showTodo(' +choice +')');

      this.app.storage.set( 'app.lmra_date', this.app.GMTToLocal());
      this.app.storage.set( 'app.lmra_choice', choice);
    },

    getModules(fk_installation) {
      console.log('getModules(' +fk_installation +')');
      var modules = this.app.storage.get('info.module');
      var result = [];
      for( var d_index in modules ) {
        if( modules[d_index].fk_installation == fk_installation ) {
          result.push(modules[d_index]);
        }
      }
      return result;
    },

    doloadModule(module) {
      console.log('doloadModule(' +module +')');

      var installation = {};
      var installations = this.app.storage.get('info.installation');
      for( var p_index in installations ) {
        if( installations[p_index].id == module.fk_installation ) {
          installation = installations[p_index];
        }
      }

      let info = {
        fk_installation: module.fk_installation,
        id: module.id,
      };
      let show = {
          Installation: installation.code || '-',
          Reference: module.description || '-'
      };
      this.app.module.loadModule(module.fk_installation +'/' +module.filename, info, show);
    },

    doShowDetails(row) {
      console.log('doShowDetails');

      // Get the form info for this record so we know what to show and where to go
      let forms = this.app.storage.get('info.forms_info');
      let form = null;
      for (let cnt in forms) {
        if (forms[cnt].key == row.type) {
          form = forms[cnt];
        }
      }

      // Calculate an overview over the values
      let innerHTML = '';
      for (let column in form.columns) {
        if( row.data[form.columns[column]] ) {
          innerHTML += this.app.escapeHTML(column) + ': <span class="' + form.columns[column] + '">' + this.app.escapeHTML(row.data[form.columns[column]]) + '</span><br>';
        }
      }

      // Give the ok button something to do
      document.getElementById('infoModalOK').onclick = () => {
          this.app.loadForm(row.type, row);
      };

      // Show the modal with the buttons, with a very light backdrop (so the user sees the marker), the modal towards the bottom of the screen and only one cancel button
      document.getElementById('infoModalLabel').innerText = form.title;
      document.getElementById('infoModalDescription').innerHTML = innerHTML;
      document.getElementById('infoModal')._modal.show();
    },

    data(key) {
      let result = this.app.storage.getAll('created.' +key +'.');
      result.sort( (a,b) => {
        return (a.createdAt > b.createdAt ? -1 : 1)
      });
      return result;
    }
  },

  beforeMount() {
    this.installations = this.app.storage.get('info.installation');
    //this.modules = this.app.storage.get('info.module');

    let app = this.app.storage.get('info.app');
    if( app && app.css ) {
      let element = document.getElementById('app_css');
      if( !element ) {
        element = document.createElement('style');
        element.id = 'app_css';
      }
      element.setAttribute('type', 'text/css');
      if ('textContent' in element) {
        element.textContent = app.css;
      } else {
        element.styleSheet.cssText = app.css;
      }
      document.getElementsByTagName('head')[0].appendChild(element);
    }
  },

  mounted() {
    let els = document.querySelectorAll( 'img[data-src]' );
    els.forEach( (el) => {
      this.app.storage.loadBlob('modules.' +el.dataset.src, el.id);
    });

    // When the database is changed, it emits an event; react to it to redraw the information
    window.addEventListener('storage-changed', (data) => {
      console.log( 'storage-changed(' +data.detail.action +')' );
      this.forceRedraw++;

      // See what caused the change-event
      if( data.detail.action == 'upload' ) {
        // If the change was because of an upload, we don't have to reload the image markers
      } else {
        // A post was created, changed or removed, so possibly the marker has too, so reload the markers
        // Using even 2 requestAnimationFrame did not work, so we'll give it 1/10th of a second to paint, followed by one requestAnimationFrame :-(
        window.setTimeout(() => {
            requestAnimationFrame(() => {
                this.app.module.markersDisplay();
            });
        }, 100);
      }
    });
  }
}
</script>